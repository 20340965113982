import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    FW2021Campaign: (props) => props.overlay ? ({
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        zIndex: 20,
        transition: 'opacity 500ms',
        opacity: !props.viewed ? 1 : 0,
    }) : ({
        position: 'relative', 
    }),
    backdrop: {
        backgroundColor: '#fafafa',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
        opacity: 0.95,
    },
    dismiss: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: '1em',
        fontSize: 24,
        lineHeight: 1,
        cursor: 'pointer',
        padding: '.5em',
    },
    container: ({ overlay }) => ({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: overlay ? '100vh' : 'none',
        width: overlay ? theme.breakpoints.values['lg'] : '100%',
        maxWidth: '100%',
        margin: 'auto',
        backgroundColor: '#fafafa',

        '& > $dismiss': {
            margin: '2em',
        },

        [theme.breakpoints.up('lg')]: {
            width: theme.breakpoints.values['lg'],
        }
    }),
    campaign_card: ({ overlay }) => ({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem',
        width: 760,
        maxWidth: '100%',
        height: 'auto',
        maxHeight: overlay ? '100%' : 'none',
        overflowY: 'auto',
        // backgroundColor: '#fafafa',
        // borderRadius: 4,
        // boxShadow: `0 5px 35px -15px #16161d88`,
        // marginTop: '-1rem',

        '& > *': {
            margin: 'auto',
        },

        '& > $dismiss': {
            margin: '1em',
        },

        [theme.breakpoints.up('md')]: {
            padding: '1rem 4rem',
        },
    }),
    fw2021_text: {
        textTransform: 'uppercase',
        textAlign: 'center',
        letterSpacing: '.3em',
        lineHeight: 2,
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    fw2021_h: {
        display: 'block',
        margin: '2rem 0',
    },
    fw2021_p: {
        display: 'block',
        margin: '2.5rem 0 0',
        '& span': {
            display: 'block',
        }
    },
    fw2021_form: {
        display: 'block',
        padding: '3rem 0',
        [theme.breakpoints.up('sm')]: {
            padding: '3rem 4rem',
        },
        [theme.breakpoints.up('md')]: {
            padding: '3rem',
        }
    },
    fw2021_form_text: {
        fontSize: 14,
    },
    mce_confirmation: {
        display: 'block',
        padding: '2rem 0    ',
    },
    submitBtn: {
        width: 200,
        margin: 'auto',
        color: '#fafafa',
    }
}));