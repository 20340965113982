import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby'
import Image from '../image/image';
import MailchimpEmbedForm from '../forms/MailchimpEmbedForm';
import { useLocalStorage } from '../utility/session';
import useStyles from './FW2021Campaign.css';

// overlay prop determines whether to control component as a dismissible overlay or not
const FW2021Campaign = ({ overlay }) => {

    const data = useStaticQuery(graphql`
        query {
            FW2021Campaign: file(relativePath: { eq: "campaign/FW2021_Collection_01.jpg" }) {
                relativePath
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    const ref = React.useRef();

    // set state to true upon rendering page
    const [viewed, setViewed] = useLocalStorage('fw2021_campaign', true);
    
    const stopPropagation = (e) => e.stopPropagation();

    const handleDismiss = () => {
        const root = ref.current;
        root.style.opacity = 0;
        root.ontransitionend = () => {
            setViewed(true);
            root.ontransitionend = null;
        }
    }

    const classes = useStyles({ overlay, viewed });
    // only prevent rendering if using component as overlay
    const showContent = overlay ? !viewed : true;

    // only render if no value is found in the cache
    return showContent && (
        <div ref={ref} id="fw2021-campaign" className={classes.FW2021Campaign}>
            {overlay && (
                <div className={classes.backdrop}
                    onClick={stopPropagation}
                    onScroll={stopPropagation}
                />
            )}
            <div className={classes.container}>
                <div className={classes.campaign_card}>
                    <Image
                        fluid={data.FW2021Campaign.childImageSharp.fluid}
                        width={400}
                        height="auto"
                        fit="contain"
                        scale={{ xs: .6, sm: 1, md: 1, lg: 1, xl: 1 }}
                    />
                    <div className={classes.fw2021_text}>
                        <h2 className={classes.fw2021_h}>Fall / Winter 21</h2>
                        <p className={classes.fw2021_p}>
                            An in-person collection launch event will take
                            place on <strong>December 4th</strong> from <strong>11:00 AM - 4:30 PM</strong> in
                            Los Angeles. Please sign up below
                            to receive your digital invitation with
                            additional event details.
                        </p>
                        <p className={classes.fw2021_p}>
                            Web shop opens <strong>December 5th</strong>.
                        </p>
                    </div>
                    <MailchimpEmbedForm />
                </div>
                {overlay && <div className={classes.dismiss} onClick={handleDismiss}>&#10005;</div>}
            </div>
        </div>
    );
}

FW2021Campaign.propTypes = {
    overlay: PropTypes.bool,
}

FW2021Campaign.defaultProps = {
    overlay: false,
}

export default FW2021Campaign;