import React from 'react';
import _ from 'lodash';
import { Link } from 'gatsby';
import { useShopifyBuy } from 'components/context/ShopifyBuy';
import { Grid, Box, Typography } from '@material-ui/core';
import LazyImg from 'components/image/LazyImg';
import RiceSpinner from 'components/spinner/RiceSpinner';
import ShopAlert from 'components/alerts/ShopAlert';
import makeStyles from './ShopGallery.css.js';

/*
    Product payload is built with ShopifyBuy API -- not graphql query
     - Get prices via variants (assume one variant)
*/
const ShopGallery = ({ loadingView = null, defaultView = null }) => {

    const shopify = useShopifyBuy();
    const lineItems = _.get(shopify, 'checkout.lineItems', []);

    const [pending, setPending] = React.useState(true);
    const [fetchError, setFetchError] = React.useState(null);
    const [inventory, setInventory] = React.useState({}); // shape: { [group name]: ShopifyProduct[] }
    const [filter, setFilter] = React.useState("all");

    // Fetch all collections when product groups are sorted by collection type
    const fetchAllCollections = async function(){
        return shopify.client.collection.fetchAllWithProducts();
    }

    // Fetch single collection when product groups are sorted by tags
    const fetchSingleCollection = async function(collectionId){
        return shopify.client.collection.fetchWithProducts(collectionId);
    }

    const fetchShop = async () => {
        setPending(true);

        try {
            const allCollections = await fetchAllCollections();

            // get fw2021 collections
            // const tableware = allCollections.find(c => c.handle === 'fall-winter-21') || [];
            // const vases = allCollections.find(c => c.handle === 'fall-winter-21-vases') || [];
            // const jokull = allCollections.find(c => c.handle === 'jokull-vases') || [];

            // sort collection products into lists
            // setInventory({
            //     all: tableware.products.concat(vases.products).concat(jokull.products),
            //     tableware: tableware.products,
            //     vases: vases.products.concat(jokull.products)
            // });

            const fw2022Collection = allCollections.find(c => c.handle === 'fall-winter-2022') || [];
            setInventory({ all: fw2022Collection.products });

            setFetchError(false);
        } catch (err) {
            console.error('Error fetching all Shopify products: ', err);
            setFetchError(err);
        } finally {
            setPending(false);
        }
    }
    
    const handleChangeList = (handle) => (e) => {
        e.preventDefault();
        setFilter(handle);
    }

    const getProductPriceText = (product) => {
        if (product.availableForSale) {
            if (typeof lineItems === 'object' && lineItems.find(item => item.variant.id === product.variants[0].id)) {
                return 'In Cart';
            } else {
                const price = Math.floor(_.get(product, 'variants.0.priceV2.amount', 0));
                return `$${price}`;
            }
        } else {
            return 'SOLD OUT';
        }
    }

    React.useEffect(() => {
        if (shopify.mounted) {
            fetchShop();
        }
    }, [shopify.mounted])

    const classes = makeStyles();

    // Render conditions
    if (pending) {
        return loadingView;
    } else if (fetchError) {
        return (
            <ShopAlert type="alert">
                Failed to connect to the shop. Please{` `}
                <a onClick={fetchShop}>reload</a>
                {` `}the page or{` `}
                <Link to="/#contact">contact us</Link>
                {` `}for support.
            </ShopAlert>
        );
    } else if (typeof inventory === 'object' && inventory.hasOwnProperty('all')) {
        // render gallery
        return (<>
            <Box mb={2} className={classes.galleryHead}>
                {/* FW 2021 {[
                    { title: 'Fall / Winter 2021',  group: 'all'         },
                    { title: 'Tableware',           group: 'tableware'   },
                    { title: 'Vases',               group: 'vases'       },
                ].map(({title, group}, i) => (
                    <Box
                        key={group}
                        className={classes.galleryHeadItem}
                        data-collection={group}
                        color={group === filter ? 'secondary.dark' : 'text.primary'}
                        style={{ pointerEvents: group === filter ? 'none' : 'auto' }}
                        onClick={handleChangeList(group)}
                    >
                        {title}
                    </Box>
                ))} */}
                {[
                    { title: 'Fall / Winter 2022',  group: 'all' },
                ].map(({title, group}, i) => (
                    <Box
                        key={group}
                        className={classes.galleryHeadItem}
                        data-collection={group}
                        color={group === filter ? 'secondary.dark' : 'text.primary'}
                        style={{ pointerEvents: group === filter ? 'none' : 'auto' }}
                        onClick={handleChangeList(group)}
                    >
                        {title}
                    </Box>
                ))}
            </Box>
            <Grid container component="ul" className={classes.ShopGallery}>
                {inventory[filter].map(product => !!product && (
                    <Grid key={product.handle} item xs={12} sm={6} md={4} component="li" className={classes.gridItem}>
                        <Link to={`/shop/product?h=${product.handle}`}>
                            <div className={classes.galleryItem}>
                                <LazyImg
                                    src={_.get(product, 'images.0.src', null)}
                                    wrapperProps={{ className: classes.galleryImage}}
                                >
                                    <RiceSpinner size="5rem" />
                                </LazyImg>
                                <Box className={classes.galleryText}>
                                    <Typography component="h3" variant="overline">
                                        <Box component="span" color="black" textAlign="left">
                                            {product.title}
                                        </Box>
                                        <Box component="span" color="primary.dark" ml={1}>
                                            {getProductPriceText(product)}
                                        </Box>
                                    </Typography>
                                </Box>
                            </div>
                        </Link>
                    </Grid>
                ))}
            </Grid>
        </>);
    } else {
        // default view
        return defaultView;
    }
}

export default ShopGallery;