import _ from 'lodash';
import clsx from 'clsx';
import React from 'react';
import { Box, Grid, TextField } from '@material-ui/core';
import FormButton from './FormButton';
import { useScript } from '../utility/client';
import './MailchimpEmbedForm.scss';
import useStyles from '../campaigns/FW2021Campaign.css';

const MailchimpEmbedForm = (props) => {

    const formRef = React.useRef();

    const [pending, setPending] = React.useState(false);

    useScript('//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js');

    // React.useEffect(() =>)
    useScript(null, `(function ($) { window.fnames = new Array(); window.ftypes = new Array(); fnames[0] = 'EMAIL'; ftypes[0] = 'email'; fnames[1] = 'FNAME'; ftypes[1] = 'text'; fnames[2] = 'LNAME'; ftypes[2] = 'text'; fnames[3] = 'ADDRESS'; ftypes[3] = 'address'; fnames[4] = 'PHONE'; ftypes[4] = 'phone'; }(jQuery)); var $mcj = jQuery.noConflict(true);`);

    // mailchimp form submission side effect -- also send email to google sheet
    const handleSubmitSideEffect = () => {
        // throttle submit button for a few seconds
        setPending(true);
        setTimeout(() => {
            setPending(false);
        }, 3000);


        let emailInput = document.getElementById('mce-EMAIL');
        if (emailInput && !!emailInput.value) {
            let email = emailInput.value.trim();

            if (!!email) {
                fetch('/.netlify/functions/subscribeMailingList', {
                    method: 'POST',
                    body: JSON.stringify({ email }),
                });
            }
        }
    }

    const classes = useStyles(props);

    return (
        <div id="mc_embed_signup" className={classes.fw2021_form}>
            <form action="https://bigcartel.us19.list-manage.com/subscribe/post?u=d4b6755eaf85c836bb4f62edf&amp;id=8e7604875e"
                method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank"
                noValidate onSubmit={handleSubmitSideEffect}>
                <div id="mc_embed_signup_scroll">
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6} className="mc-field-group">
                            <TextField
                                id="mce-FNAME"
                                label="First Name"
                                name="FNAME"
                                type="text"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    className: classes.fw2021_form_text,
                                    htmlFor: "mce-FNAME",
                                }}
                                InputProps={{ className: classes.fw2021_form_text }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className="mc-field-group">
                            <TextField
                                id="mce-LNAME"
                                label="Last Name"
                                name="LNAME"
                                type="text"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    className: classes.fw2021_form_text,
                                    htmlFor: "mce-LNAME",
                                }}
                                InputProps={{ className: classes.fw2021_form_text }}
                            />
                        </Grid>
                        <Grid item xs={12} className="mc-field-group">
                            <TextField
                                id="mce-EMAIL"
                                label="Email"
                                name="EMAIL"
                                type="email"
                                fullWidth
                                variant="outlined"
                                required
                                InputLabelProps={{
                                    className: classes.fw2021_form_text,
                                    htmlFor: "mce-EMAIL"
                                }}
                                InputProps={{ className: classes.fw2021_form_text }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="block" textAlign="center" mt={3}>
                                <FormButton
                                    type="submit"
                                    className={classes.submitBtn}
                                    id="mc-embedded-subscribe"
                                    disabled={pending}
                                >
                                    Request Invitation
                                </FormButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <div id="mce-responses" className={clsx(classes.mce_confirmation, classes.fw2021_text)}>
                        <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                        <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                    </div>
                    {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                    <div style={{ position: 'absolute', left: -5000 }} aria-hidden="true">
                        <input type="text" name="b_d4b6755eaf85c836bb4f62edf_8e7604875e" tabIndex="-1" defaultValue="" />       
                    </div>
                </div>
            </form>
        </div>
    );
}

export default MailchimpEmbedForm;