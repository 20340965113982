import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    FW2022Teaser: {
		textAlign: 'center',
    },
    animationBlock: {
		// margin: 0,
		// [theme.breakpoints.up('md')]: {
			marginTop: theme.spacing(-12),
			marginBottom: 0,
		// }
	},
	animationContainer: {
		margin: 'auto',
		width: '100%',
		maxWidth: 700,
		pointerEvents: 'none',
	},
	// text block parent sets the title's base font size
	textBlock: {
		marginTop: theme.spacing(-4),
		marginBottom: '4em',
		textAlign: 'center',
		fontSize: '1.1em',
		[theme.breakpoints.up('md')]: {
			fontSize: '1.5em',
		},
	},
	// title is default font size of parent
	title: {
		display: 'block',
		fontFamily: 'interstate-mono, monospace',
		fontWeight: 300,
		fontStyle: 'normal',
		textTransform: 'uppercase',
		fontSize: '1em',
		marginTop: 0,
		marginBottom: '1em',
	},
	// subtitle is em ratio of title
	subtitle: {
		display: 'block',
		fontFamily: 'scotch-display, sans-serif',
		fontSize: '0.6em',
		fontWeight: 600,
		fontStyle: 'italic',
		textTransform: 'uppercase',
		letterSpacing: '.2em',
	},
	// wrapper over subscribe content
	subscribeWrapper: {
		fontSize: '0.9em',
		[theme.breakpoints.up('md')]: {
			fontSize: '1em',
		}
	}
}));