import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

export default makeStyles(theme => {

    const defaultImgSize = theme.components.shop.gallery.item.size - 32;

    return {
        galleryHead: {
            position: 'relative',
            top: 0,
            backgroundColor: theme.palette.background.default,
            zIndex: 2,
            padding: theme.spacing(0,1),
            [theme.breakpoints.up('sm')]: {
                position: 'sticky',
                padding: theme.spacing(0,4),
            }
        },
        galleryHeadItem: {
            display: 'block',
            lineHeight: 1.5,
            textTransform: 'uppercase',
            cursor: 'pointer',
            margin: 0,
            fontSize: 15,
            [theme.breakpoints.up('sm')]: {
                fontSize: 18,
                display: 'inline',
                lineHeight: 3,
                '&:not(:last-of-type)': {
                    marginRight: theme.spacing(4),
                }
            }
        },
        ShopGallery: {
            position: 'relative',
            zIndex: 1,
            listStyle: 'none',
            padding: 0,
            margin: 0,
            '& li': {
                margin: 0,
                padding: 0,
            }
        },
        gridItem: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '100%',
            textAlign: 'center',
            [theme.breakpoints.only('sm')]: {
                '&:nth-child(odd)': {
                    justifyContent: 'flex-end',
                },
                '&:nth-child(even)': {
                    justifyContent: 'flex-start',
                }
            }
        },
        galleryItem: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
            padding: theme.spacing(2),
            width: theme.components.shop.gallery.item.size,
            '&:hover': {
                '& $galleryImage': {
                    opacity: 0.8,
                },
                '& $galleryText': {
                    opacity: 0.5,
                }
            }
        },
        galleryImage: {
            transition: '150ms',
            width: defaultImgSize,
            height: defaultImgSize,
            overflow: 'hidden',
            '& img': {
                width: 'inherit',
                height: 'inherit',
                objectFit: 'cover',
                objectPosition: 'center center',
            },
        },
        galleryText: {
            textAlign: 'center',
            fontWeight: 'normal',
            display: 'block',
            width: '100%',
            padding: theme.spacing(1, 0.5),
            '& h3': {
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                lineHeight: 1.2,
            }
        },
    };
});