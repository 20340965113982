import React from 'react';
import lottie from 'lottie-web';
import mtnAnimData from 'components/animations/fw2022-mountain-animation.json';
import useStyles from './FW2022Teaser.css';

const FW2022Teaser = ({ subscribeHtml, closed, ...props}) => {

    const animationRef = React.useRef();

    // on mount
    React.useEffect(() => {
        // init fw2022 mountain animation
        const mtnAnimation = lottie.loadAnimation({
            container: animationRef.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: mtnAnimData,
        });

        // clean up on dismount
        return () => mtnAnimation.destroy();
    }, []);

    const css = useStyles();

    return (
        <div class={css.FW2022Teaser}>
            <div class={css.animationBlock}>
                <div ref={animationRef} class={css.animationContainer} />
            </div>
            <div class={css.textBlock}>
                <h4 class={css.title}>Fall Winter 2022</h4>
                {closed ? (
                    <h5 class={css.subtitle}>Shop is now closed</h5>
                ) : (
                    <h5 class={css.subtitle}>Coming Soon</h5>
                )}
            </div>
            {subscribeHtml && (
                <div class={css.subscribeWrapper}>
                    {subscribeHtml}
                </div>
            )}
        </div>
    );
}

export default FW2022Teaser;