import _ from 'lodash';
import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout/main';
import PageTitle from 'components/page/title';
import Image from 'components/image/image';
import { Box, Typography } from '@material-ui/core';
import ShopGallery from 'components/shop/ShopGallery';
import SubscribeForm from 'components/forms/SubscribeForm';
import FW2021Campaign from 'components/campaigns/FW2021Campaign';
import FW2022Teaser from 'components/campaigns/FW2022Teaser';
import RiceSpinner from 'components/spinner/RiceSpinner';

const loadingView = (
    <Box p={12} display="block">
        <RiceSpinner size="8rem" />
    </Box>
);

const ShopPage = (props) => {

    const comingSoonImage = _.get(props, 'data.comingSoonImage.publicURL', null);

    const subscribeAction = (msg) => (
        <Box my={8}>
            {msg && (
                <Box display="block" color="primary.dark">
                    {msg}
                </Box>
            )}
            <Box py={4}>
                <SubscribeForm />
            </Box> 
        </Box>
    )

    // fallback html
    const defaultHtml = {
        message: (msg, subscribe = false) => (
            <Box display="block" textAlign="center">
                <Image src={comingSoonImage} />
                <Typography><em style={{ textTransform: 'uppercase' }}>{msg}</em></Typography>
                {subscribe && subscribeAction('Subscribe to our mailing list for updates on future releases')}
            </Box>
        ),
        launch: (date) => (<>
            <Image src={comingSoonImage} /> 
            <Box fontWeight="bold" fontStyle="italic" component="h4" display="block" mt={0} mb={0.5} fontSize="1.1em" style={{ textTransform: 'uppercase' }}>
                Shop opening {date}
            </Box>
            {subscribeAction("Subscribe to our mailing list for updates")}
        </>),
    }

    const shopContent = (envShopStatus) => {
        switch (envShopStatus) {
          case 'online':
            return (
                <ShopGallery
                    loadingView={loadingView}
                    defaultView={defaultHtml.message("Shop under maintenance")}
                />
            );
            case 'fw2021-campaign':
                return <FW2021Campaign />;
            case 'fw2021-closed':
                return defaultHtml.message("Fall/Winter 2021 Collection Shop is now closed", true);
            case 'fw2022-coming':
                return <FW2022Teaser subscribeHtml={subscribeAction("Subscribe to our mailing list for updates")} />;
            case 'fw2022-closed':
                return <FW2022Teaser closed subscribeHtml={subscribeAction("Subscribe to our mailing list for updates")} />;
            case 'launch-date':
                // Specify a launch date with env variables to programmatically open the shop
                const timestamp = Date.parse(process.env.GATSBY_SHOP_LAUNCH_DATE);
                // show shop if it is valid and is before current time
                const showShop = !isNaN(timestamp) && Date.now() > timestamp;
                if (showShop) {
                    return shopContent('online');
                }

                // Specify a launch fallback view if the launch date has not been reached
                if (process.env.GATSBY_SHOP_LAUNCH_FALLBACK) {
                    return shopContent(process.env.GATSBY_SHOP_LAUNCH_FALLBACK);
                }

                // If no render conditions met, fallback to default view (closed)
                return shopContent('default');
            default:
                return defaultHtml.message("IW Shop is now closed", true);
        }
    }

    return (
        <Layout containerSize="md" enableCart>
            <PageTitle navOffset>Shop</PageTitle>
            <Box pt={8} pb={12}>
                {shopContent(process.env.GATSBY_SHOP_STATUS)}
            </Box>
        </Layout>
    );  
}

export const query = graphql`
    query {
        comingSoonImage: file(relativePath: { eq: "comingsoon.svg" }) {
            publicURL
        }
    }
`;

// export const query = graphql`
//     allShopifyProduct {
//         edges {
//             node {
//                 availableForSale
//                 id
//                 tags
//                 title
//                 description
//                 descriptionHtml
//                 handle
//                 productType
//                 shopifyId
//                 totalInventory
//                 priceRange {
//                     maxVariantPrice {
//                         amount
//                         currencyCode
//                     }
//                     minVariantPrice {
//                         amount
//                         currencyCode
//                     }
//                 }
//                 images {
//                     originalSrc
//                     localFile {
//                         publicURL
//                         childImageSharp {
//                             resolutions {
//                                 aspectRatio
//                             }
//                             fluid(maxHeight: 1000) {
//                                 ...GatsbyImageSharpFluid
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `

// export const query = graphql`
//     query {
//         allFakeShopCollectionYaml {
//             edges {
//                 node {
//                     id
//                     title
//                     description
//                     handle
//                     productType
//                     priceRange {
//                         minVariantPrice {
//                             amount
//                             currencyCode
//                         }
//                         maxVariantPrice {
//                             amount
//                             currencyCode
//                         }
//                     }
//                 }
//             }
//         }
//     }
// `

export default ShopPage;